@import 'settings';
@import 'util/util';

@mixin menu-icon($size, $thickness, $color)  {
	display: block;
	width: $size;
  height: $thickness;
  position: relative;
  background: $color; 
	opacity: 1;

  &:before {
		content: '';
  	position: absolute;
  	left: 0;
  	top: -#{0.5*($size - $thickness)};
  	width: $size;
  	height: $thickness;
  	background:$color; 
  	transition: top 0.2s ease-out,
  	transform 0.2s ease-out;
	}

  &:after {
		content: '';
  	position: absolute;
  	left: 0;
  	top: #{0.5*($size - $thickness)};
  	width: $size;
  	height: $thickness;
  	background:$color; 
  	transition: top 0.2s ease-out,
  	transform 0.2s ease-out;
	}

	&.closed {
		background: transparent !important;

		&:before {
			top: 0;
			transform: rotate(45deg);
		}

		&:after {
			top: 0;
			transform: rotate(-45deg);
		}
	}
}

html, body {
  position: relative;
  height: 100%;
}

html {
	font-size: 14px;
	@include breakpoint(medium) {
		font-size: $global-font-size;		
	}
}

/*body {
	overflow: hidden;
}

#container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: -20px;
	overflow-y: scroll;
	&.touch{
		right: 0;
	}
}*/

#container {
	padding-left:0px;
	@include breakpoint (large) {
		padding-left:60px;
	}
}

h1 {
	font-size: 30px;
	@include breakpoint (medium) {
		font-size: 60px;
	}
}

h2 {
	color: $white;
	margin-bottom: 5px;
}

h4 {
	font-size: 16px;
	@include breakpoint (medium) {
		font-size: 18px;
	}
}

.lead {
	font-size: 22px;
	font-family: $header-font-family;
	font-style: italic;
	color: $blue;
	line-height: 1.4;
	margin: 30px 0;
	padding: 30px 30px;
	border-left: 4px solid $blue;
	background: transparentize($blue,0.95);
}

img {
	margin-top: 50px;
}

em {
	display: block;
	font-size:14px;
	font-style: normal;
	line-height:1.3;
	margin-bottom: 50px;
}

figure {
	position: relative;
	height: 0;
	& > div, 
	& > figcaption {
		position:absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&.height25 {
		padding-top:25%;
	}
	
	&.height50 {
		padding-top:50%;
	}

	&.height70 {
		padding-top:70%;
	}

	&.height100 {
		padding-top:100%;
	}

	&.double {
		padding-top: 100%;
		@include breakpoint(medium) {
			padding-top: calc(50% - 8px);
		}
	}
	figcaption {
		opacity: 0;
		background: rgba(0,85,150,0.9);
		color: $white;
		padding: $global-padding;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		transition: all 0.5s ease-out;
		& > * {
			transform: scale(0.9);
			transition: all 0.4s ease-out;
		}
		h6 {
			font-size: 14px;
			color: $white;
		}
		p {
			font-size: 12px;
		}
		& > *:last-child {
			margin-bottom: 0;
		}
	}

	&:hover {
		figcaption {
			opacity: 1;
			& > * {
				transform: scale(1);
			}
		}
	}
}

.scrollbar-measure {
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
}

#main-nav {
	z-index: 9999;
	position:fixed;
	top: 0;
	bottom: 0;
	left: 0px;
	width: 280px;
	background: rgb(0,20,40);
	transition: 0.5s transform ease-in-out;
	transform: translateX(-280px);
	padding: 0;

	ul, ol {
		list-style: none;
		text-align: left;
		margin: 20px 0;
		& > li {
			padding: 10px 0;
			&:hover, &:active, &:focus {
				background: rgba(0,40,80,1);
			}

			&.active {
				background: $blue;
				&:hover, &:active, &:focus {
					background: $blue;
				}
			}
		}
		& > li > a {
			color: $white;
			font-size: 16px;
			font-weight: bold;
			position: relative;
			display: inline-block;
			transition: all 0.3s ease-out; 
			padding-left: 30px;

			/*&.active {
				color: rgb(150,200,255);
			}*/

			span {
				display: inline-block;
				font-weight: bold;
				font-size: 20px;
				line-height: 36px;
				margin-right: 30px;
			}
		}
	}

	.button {
		width: 40px;
		height: 40px;
		display: block;
		position: absolute;
		top: 0;
		right: -40px;
		background: rgba(0,20,40,0.8);
		padding: 0;
		border: 0;

		i {
			display: block;
			position: absolute;
			width: 20px;
			height: 1px;
			top: 19px;
			left: 10px;
			background: $white;
			transition: 0.5s all ease-in-out;
			&:before {
				content: '';
				display: block;
				width: 20px;
				height: 1px;
				background: $white;
				position: absolute;
				top: -10px;
				left: 0;
				transform: rotate(0);
				transition: 0.5s all ease-in-out;
			}

			&:after {
				content: '';
				display: block;
				width: 20px;
				height: 1px;
				background: $white;
				position: absolute;
				top: 10px;
				left: 0;
				transform: rotate(0);
				transition: 0.5s all ease-in-out;
			}
		}
	}

	.button:focus, .button:active, .button:hover {
		outline: 0;
	}

	&.active {
		transform: translateX(0%);

		.button {
			i {
				background: transparent;

				&:before {
					top: 0px;
					left: 0px;
					transform: rotate(-45deg);
				}

				&:after {
					top: 0px;
					left: 0px;
					transform: rotate(45deg);
				}
			}
		}
	}

	@include breakpoint(large) {
		display: block;
		position: fixed;
		overflow: hidden;
		bottom: 0;
		left: 0;
		right: auto;
		top: 0;
		z-index: 100;
		padding: 0 0px;
		background: rgba(0,20,40,1);
		color: $white;
		//border-top-left-radius: 10px;
		//border-bottom-left-radius: 10px;
		width: 60px;
		transform: translateX(0);
		opacity: 1;
		transition: all 0.3s ease-out;

		&:hover, &:active, &:focus {
			width: 330px;
			opacity: 1;
		}
					
		ul, ol {
			list-style: none;
			position: relative;
			text-align: left;
			margin: 0;
			padding-left: 0px;
			height: 100%;

			& > li {
				display: block;
				margin: 0;
				padding: 10px 30px 10px 15px;   

				&:hover, &:active, &:focus {
					background: rgba(0,40,80,1);
				}

				&.active {
					background: $blue;
					&:hover, &:active, &:focus {
						background: $blue;
					}
				}
			}

			& > li > a {
				display: block;
				white-space: nowrap;
				color: $white;
				font-size: 16px;
				font-weight: bold;
				position: relative;
				display: inline-block;
				transition: all 0.3s ease-out; 
				padding-left: 0;
				
				span {
					display: inline-block;
					font-weight: bold;
					font-size: 20px;
					line-height: 36px;
					margin-right: 20px;
					padding: 0 4px;
				}
			}
		}		
	}

	.road {
		position: absolute;
		top: 0;
		left: 2px;
		width: 50px;
		background-image: url('../img/road.svg');
		background-repeat: repeat-y;
	}
	.road-empty {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 2px;
		width: 50px;
		background-image: url('../img/road-empty.svg');
		background-repeat: repeat-y;
	}
}

.separator-road {
	height: 16px;
	width: 100%;
	background-image: url('../img/road-separator.svg');
	background-repeat: repeat-x;
	background-position: center;
	position:relative;
	display: block;
	margin: 30px 0;
	opacity: 0.5;
}

.section {
	$section-header-height: 800px;
	position: relative;
	z-index: 1;
	.section-anchor {
		position:absolute;
		top: 20px;
	}
	.section-header {
		position: relative;
		height: $section-header-height;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h1 {
			color: $white;
			text-shadow: 0 0 100px rgba(0,0,0,0.3);
			text-align: center;
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.section-content {
		position: relative;
		background: $white;
		padding-top: 100px;
		padding-bottom: 150px;
		padding-left: 15px;
    padding-right: 15px;
		.separator-road {
			background-image: url('../img/road-separator-blue.svg');
			margin: 30px 0;
		}
	}
	h3:not(:first-child) {
		margin-top: 100px;
	}
}

.intext-image {
	text-align: center;
	width:100%; 
	margin-right:40px; 
	margin-bottom: 20px; 
	float:left;
	@include breakpoint(medium) {
		width:50%; 
		text-align: left;
	}
}

#intro-section {
	.section-content {
		background: rgb(0,40,80);
		color: $white;
		display: flex;
		align-items: center;
	}
	.disclaimer {
		position: absolute;
		line-height: 1.1;
		top: 0;
		right: 0;
		left: 0;
		font-size: 12px;
		padding-right: 130px;
		padding-left: 30px;
		text-align: right;
		padding-top: 35px;
		color: $white;
		background: url('../img/gerb-right.png') top right no-repeat;
		background-size: contain;
		height: 128px;

		@include breakpoint(medium) {
			left: auto;
			padding-left: 0px;
			font-size: 15px;
		}

	}
	h1 {
		transform: translateY(0);
		line-height: 0.9;
		padding: 0 50px;
		@include breakpoint(medium) {
			transform: translateY(-10%);
		}
	}
	h3 {
		color: $white;
	}
	h4 {
		font-family: $body-font-family;
		font-weight: normal;
		line-height: 1.4;
		color: $white;
		text-align: center;
		transform: translateY(0);
		padding: 0 15px;
		@include breakpoint(medium) {
			transform: translateY(-10%);
		}
	}

}

.source {
	position: relative;
	font-size: 12px;
	padding: 40px 0;
	background: $light-gray;
	@include breakpoint (medium) {
		font-size: 14px;
	}
}

.illustration {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;

	img {
		margin-top: 20px;
		margin-bottom: 15px;
	}

	figure {
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.grid {
		@include grid-row();
		margin-left: -5px;
		margin-right: -5px;
	}
	.block {
		@include grid-column(12 of 12, 10px);
		@include breakpoint(medium) {
			@include grid-column(6 of 12, 10px);
		}
	}

	p {
		font-size: 13px;
	}
}


#bg {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0px;
	@include breakpoint (large) {
		left: 60px;
	}
	right: 0;
	background-color: rgb(0,40,80);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	&:before {
		content:'';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

	}

	&:after {
		content:'';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0,0,0,0.3);
	}

	&.bg0:before {
		background-image: url('../img/160.jpg');
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.bg1:before {
		background-image: url("../img/kulibin.jpg");
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.bg2:before {
		background-image: url('../img/nizhniy-1910.jpg');
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.bg3:before {
		background-image: url('../img/1907.jpg');
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.bg4:before {
		background-image: url('../img/ww1.jpg');
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.bg5:before {
		background-image: url('../img/amo-f-15.jpg');
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.bg6:before {
		background-image: url('../img/section6.jpg');
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.bg7:before {
		background-image: url('../img/section8.jpg');
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.bg8:before {
		background-image: url('../img/section92.jpg');
		animation: bg-scale 20s ease-in-out;
		animation-fill-mode: forwards;
	}
}

@keyframes bg-scale {
  0%   { transform: scale(1); }
  100% { transform: scale(1.1); }
}